<template>
  <div
    :class="[
      'organism accordion',
      randomUniqueClass,
      'container-' + organism.containerWidth,
    ]"
  >
    <div
      v-for="(item, index) in organism.item"
      :key="'item-' + index"
      :id="item.id"
      :class="[
        item.cssClasses,
        'mx-auto max-w-screen-xl border-b last:border-b-0 border-gray-400 py-10 mx-8',
      ]"
    >
      <h3
        class="accordion-header font-figtree text-xl md:text-[28px] font-semibold text-fr-secondary-dark"
      >
        <button
          aria-expanded="false"
          :aria-controls="'accordion-content-' + index"
          class="grid grid-cols-[1fr_2rem] items-center w-full text-left cursor-pointer text-fr-secondary-dark hover:opacity-80"
        >
          <div class="flex flex-col">
            <span class="text-2xl md:text-[28px] title">{{ item.title1 }}</span>
            <span
              v-if="item.title2"
              class="text-base font-medium text-gray-400 md:text-lg subtitle font-figtree"
              >{{ item.title2 }}</span
            >
          </div>
        </button>
      </h3>
      <div
        class="accordion-content overflow-hidden transition-[max-height] duration-300 aria-hidden:max-h-0 max-h-full"
        :id="'accordion-content-' + index"
        aria-hidden="true"
      >
        <div
          v-html="item.description"
          class="text-gray-500 description mt-7"
        ></div>
        <MoleculesButtons
          v-if="item.button1"
          :buttons="item"
          :button-colors="[
            item.button1Color ?? 'button-blue',
            item.button2Color ?? 'button-white',
            item.button3Color ?? 'button-white',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<style>
.accordion {
  .accordion-header {
    button {
      &::after {
        @apply w-7 h-7 bg-no-repeat block bg-contain;
        content: "";
        background-image: url("/img/heroicon-plus-circle--blue.png");
      }
      &[aria-expanded="true"]::after {
        background-image: url("/img/heroicon-minus-circle--blue.png");
      }
    }
  }

  .accordion-content {
    .buttons {
      @apply mt-7 mb-1;
    }
  }
}
</style>
<script setup>
const props = defineProps({
  organism: Object,
});

// Need to scope the CSS selectors with a unique classname to prevent issues when multiple accordion organisms are present on the page.
const randomUniqueClass = "accordion-" + generateRandomString(8);

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }

  const headersSelector = "." + randomUniqueClass + " .accordion-header button";
  const accordionHeaders = document.querySelectorAll(headersSelector);

  accordionHeaders.forEach((header) => {
    header.addEventListener("click", () => {
      const accordionContentId = header.getAttribute("aria-controls");
      const contentSelector =
        "." + randomUniqueClass + " #" + accordionContentId;
      const accordionContent = document.querySelector(contentSelector);
      const isExpanded = header.getAttribute("aria-expanded") === "true";

      header.setAttribute("aria-expanded", !isExpanded);
      accordionContent.setAttribute("aria-hidden", isExpanded);
    });
  });
});
</script>
