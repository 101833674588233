<template>
  <div class="flex items-center overview-group-heading">
    <div class="w-full h-0 border-[1px] border-gray-900 border-solid"></div>
    <h3
      class="uppercase mx-3 whitespace-nowrap font-figtree font-bold text-[22px] text-gray-900"
    >
      <slot>Greater Example Area</slot>
    </h3>
    <div class="w-full h-0 border-[1px] border-gray-900 border-solid"></div>
  </div>
</template>
