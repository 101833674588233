<template>
  <div class="organism events">
    <OrganismsHeadingTransition
      :organism="{ title1: 'Upcoming Events', arrow: false }"
      class="!py-[4.5rem]"
    />
    <div v-if="upcomingEvents?.length">
      <OrganismsSplitContent
        v-for="event in upcomingEvents"
        :organism="event"
        class="mb-12"
      />
    </div>
    <div v-else>
      <OrganismsImage id="no-events" :organism="noEvents" />
    </div>
    <OrganismsPastEvents />
  </div>
</template>

<style>
.events {
  .heading-transition:first-of-type {
    h2 {
      @apply !text-[52px];
    }
  }

  .split-content {
    .text {
      .title-1 {
        @apply mb-4;
      }

      .title-3 {
        @apply flex items-center text-sm text-black font-bold mb-4 before:content-[url('/img/heroicon-calendar.svg')] before:inline-block before:w-6 before:aspect-square before:mr-1;
      }

      .description {
        @apply mb-8 line-clamp-4 md:line-clamp-3;

        & > * {
          @apply hidden;
        }

        & > h1,
        & > h2,
        & > h3,
        & > h4,
        & > h5,
        & > h6 {
          @apply first:block;
        }

        & > p:first-of-type {
          @apply block line-clamp-4 md:line-clamp-3;
        }

        * {
          @apply text-base font-normal;
        }

        a {
          @apply underline;
        }
      }
    }
    img {
      @apply max-w-[min(600px,_100%)] ml-0;
    }
    .content {
      @apply gap-4 xl:gap-24;
    }
  }
  #no-events {
    @apply py-16;

    img {
      @apply max-w-full w-[672px] mx-auto;
    }
  }
}
</style>

<script setup>
import { storeToRefs } from "pinia";
const events = useEvents();
const { eventsList } = storeToRefs(events);
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

const upcomingEvents = computed(() => {
  return eventsList?.value
    .filter((event) => {
      const eventDate = zonedTimeToUtc(
        event.eventDetails.startDateTime,
        "America/Denver"
      );
      return new Date() < new Date(eventDate);
    })
    .map((event) => {
      const eventDate = zonedTimeToUtc(
        event.eventDetails.startDateTime,
        "America/Denver"
      );
      const eventDateFormatted = format(new Date(eventDate), "MMM d, yyyy");
      return {
        backgroundColor: "#f0f0f0",
        collection: event.eventDetails.collection,
        containerWidth: "full-width",
        description: event.content,
        descriptionColor: "#333",
        fullBleed: true,
        id: "event-" + event.slug,
        image: event.eventDetails.image,
        splitContentLayout: "text-image",
        title1: event.title,
        title3: eventDateFormatted,
        button1: true,
        button1Text: "Get Details",
        link1: event.uri,
        unixTimestamp: Date.parse(eventDate),
      };
    })
    .sort((a, b) => a.unixTimestamp - b.unixTimestamp);
});

const noEvents = {
  containerWidth: "full-width",
  image: {
    sourceUrl: "/img/no-events.png",
  },
};
</script>
